<template>
    <BT-Blade-Item
        @edit="loadPolicy"
        @saved="panelV = 0"
        bladeName="purchase-order"
        :canSave="panelV == 0"
        :editToggle="editToggle"
        :hideBladeHeader="hideBladeHeader"
        navigation="purchase-orders"
        :loadingMsg="loadingMsg"
        :onCanDelete="v => { return v != null && (v.confirmedOn == null || v.isInactive || (v.seller != null && v.seller.isManagedByAnotherAccount)); }"
        :onCanEdit="v => { return v != null && !v.isInactive && v.confirmedOn == null; }"
        :onCanSave="onCanSave"
        :onFinalize="() => showFinalDialog = true"
        :onGetSave="getOrderToSave"
        :onPullSuccessAsync="orderPulled"
        :onNew="newOrder"
        :small="false"
        :title="title"
        transparent>
        <template v-slot:raw="{ data, isChanged, item, msg, save }">
            <v-container v-if="item != null" class="pa-0 pa-sm-3 pb-sm-0" fluid>
                <v-slide-x-reverse-transition hide-on-leave group tag="div" class="d-flex no-gutters">
                    <v-col v-if="!$vuetify.breakpoint.mobile || currentCol == 0" key="1">
                        <v-card flat outlined class="ma-0 pa-0 transparent">
                            <v-toolbar v-if="!$vuetify.breakpoint.mobile || data.isNew || data.isEditing" class="primary" dark dense>
                                <v-btn v-if="data.isNew || data.isEditing" icon large @click="() => { data.isNew ? $BlitzIt.navigation.navBack() : stopEditing() }">
                                    <v-icon large>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ data.isNew ? 'New Order' : 'Order' }}</v-toolbar-title>
                                <v-spacer />
                                <v-btn v-if="$vuetify.breakpoint.mobile && item.supplyAgreementID != null && (data.isNew || data.isEditing)" @click="() => { currentCol = (data.isNew || data.isEditing) ? 1 : 2 }" text>Next</v-btn>
                            </v-toolbar>
                            <v-card class="ma-0 pa-0" flat :style="parentCardStyle">
                                <v-card flat class="overflow-y-auto" :style="standardCardStyle">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <BT-Select-List-Box
                                                v-if="data.isNew"
                                                @change="agrID => { selectPolicy(agrID, item); }"
                                                :canDeselect="false"
                                                dense
                                                :isEditing="data.isNew || data.isEditing"
                                                :isSelecting="item.supplyAgreementID == null"
                                                itemText="seller.companyName"
                                                itemValue="id"
                                                label="Supplier"
                                                navigation="suppliers"
                                                :onFilter="list => { return list ? list.orderBy(x => x.seller.companyName) : list; }"
                                                selectIfOneOption
                                                v-model="item.supplyAgreementID"
                                                width="900">
                                                <template v-slot:selectedItem="{ item, open }">
                                                    <v-list-item @click="open">
                                                        <BT-List-Item-Avatar v-if="item != null" v-model="item.sellerID" />
                                                        <v-list-item-content>
                                                            <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                                                            <v-list-item-title v-if="item != null">{{ item.seller ? item.seller.companyName : 'Select' }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-icon>mdi-chevron-down</v-icon>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <template v-slot="{ item }">
                                                    <BT-List-Item-Avatar v-model="item.seller.id" />
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <span v-if="item.isSuspended" class="error--text">Suspended</span>
                                                    </v-list-item-action>
                                                </template>
                                            </BT-Select-List-Box>
                                            <v-list-item v-else-if="item.seller != null" dense>
                                                <BT-List-Item-Avatar v-model="item.sellerID" />
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                                                    <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item v-else-if="currentPolicy != null" dense>
                                                <BT-List-Item-Avatar v-model="currentPolicy.sellerID" />
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                                                    <v-list-item-title>{{ currentPolicy.sellerName }}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col v-if="!data.isNew || currentPolicy != null" cols="12">
                                            <BT-Select-List-Box v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'" 
                                                v-model="item.dueDate"
                                                :canSearch="false"
                                                :isEditing="data.isNew || data.isEditing"
                                                :isSelecting="item.dueDate == null"
                                                :items="currentPolicy.orderSlots"
                                                itemText="deliverBy"
                                                itemValue="deliverBy"
                                                label="Delivery Slot"
                                                :onCanSelect="v => { return v == null || v.purchaseOrderID == null }"
                                                sublabel="(Select a delivery date below)"
                                                textFilter="toDayShortDate"
                                                width="100%">
                                                <template v-slot:selectedItem="{ item, open }">
                                                    <v-list-item @click="open">
                                                        <v-list-item-avatar>
                                                            <v-icon size="40">mdi-calendar</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-subtitle>Delivery Slot</v-list-item-subtitle>
                                                            <v-list-item-title v-if="item != null">{{ item.deliverBy | toDayShortDate }}</v-list-item-title>
                                                            <v-list-item-subtitle v-else>(Select to see delivery dates)</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-icon>mdi-chevron-down</v-icon>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <template #listItem="{ index, item }">
                                                    <v-list-item :value="item" :key="index">
                                                        <v-list-item-avatar>
                                                            <v-icon v-if="item.purchaseOrderID != null" class="success--text">mdi-calendar-check</v-icon>
                                                            <v-icon v-else>mdi-calendar-blank</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.deliverBy | toDayShortDate }}</v-list-item-title>
                                                            <v-list-item-subtitle v-if="item.orderBy != null">{{ item.orderBy | toTimeSpan }} till cut-off time</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action v-if="item.purchaseOrderID != null">
                                                            <div class="d-flex align-center justify-center">
                                                                <div class="warning--text">Order Placed</div>
                                                                <v-btn small icon target="_blank" :to="{ name: 'purchase-order', params: { id: item.purchaseOrderID }}" title="Open in new tab">
                                                                    <v-icon small>mdi-open-in-new</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-list-item-action>
                                                        <v-list-item-action v-else>
                                                            <div class="success--text">Available</div>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                            </BT-Select-List-Box>

                                            <BT-Field-Date v-else
                                                v-model="item.dueDate"
                                                :dateRules="dateRules"
                                                :dateFrom="dateFrom"
                                                icon="mdi-calendar"
                                                iconSize="40"
                                                :isEditing="data.isNew || data.isEditing"
                                                label="Delivery Date" />

                                        </v-col>
                                        <v-col v-if="!data.isNew || currentPolicy != null" cols="12">
                                            <BT-Field-Address v-if="(!data.isNew && !data.isEditing) || isArrayOfLength(possibleLocations, 1)"
                                                icon="mdi-map-marker"
                                                iconSize="40"
                                                label="Destination"
                                                :value="item.location" />
                                            <BT-Select-List-Box v-else
                                                v-model="item.locationID"
                                                :isEditing="data.isNew || data.isEditing"
                                                :isSelecting="item.locationID == null"
                                                :items="possibleLocations"
                                                itemValue="id"
                                                label="Destination"
                                                sublabel="(Select a destination below)"
                                                textFilter="toLocationLine">
                                                <template v-slot:selectedItem="{ item, open }">
                                                    <v-list-item @click="open">
                                                        <v-list-item-avatar>
                                                            <v-icon size="40">mdi-map-marker</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-subtitle>Destination</v-list-item-subtitle>
                                                            <v-list-item-title v-if="item != null">{{ item | toLocationLine }}</v-list-item-title>
                                                            <v-list-item-subtitle v-else>(Select to see locations)</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-icon>mdi-chevron-down</v-icon>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <template #listItem="{ index, item }">
                                                    <v-list-item :value="item" :key="index">
                                                        <v-list-item-avatar>
                                                            <v-icon>mdi-map-marker</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </BT-Select-List-Box>
                                            <BT-Field-String
                                                hideDetails
                                                icon="mdi-pound"
                                                iconSize="40"
                                                label="Purchase Order #"
                                                v-model="item.purchaseOrderNumber"
                                                :isEditing="data.isNew || data.isEditing" />
                                            <BT-Field-String
                                                v-if="!data.isNew"
                                                hideDetails
                                                icon="mdi-pound"
                                                iconSize="40"
                                                label="Customer Order #"
                                                v-model="item.customerOrderNumber" />
                                            <v-alert v-if="item.confirmedOn != null" type="warning">
                                                This order has been confirmed by the supplier.  Please contact the supplier if you require changes to be made.
                                            </v-alert>
                                        </v-col>
                                        <v-col v-if="data.isNew && item.supplyAgreementID != null" cols="12">
                                            <v-row no-gutters>
                                                <v-col v-if="!isLengthyArray(item.orderItems)">
                                                    <BT-Btn
                                                        icon="mdi-history"
                                                        label="Copy Last Order"
                                                        @click="() => copyLastOrder(currentPolicy, item)" />
                                                </v-col>
                                                <v-col v-if="$vuetify.breakpoint.mobile">
                                                    <BT-Btn
                                                        icon="mdi-cart"
                                                        label="Select Products"
                                                        @click="currentCol = 1" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="$vuetify.breakpoint.mobile && !data.isNew && !data.isEditing" cols="12">
                                            <v-subheader>Ordered Items</v-subheader>
                                            <v-list>
                                                <template v-for="(orderItem, index) in item.orderItems">
                                                    <Order-Line-Item
                                                        :item="orderItem"
                                                        showImages
                                                        :key="index"
                                                        @change="lineChanged" />
                                                    <v-divider :key="'d' + orderItem.productID" />
                                                </template>
                                            </v-list>
                                        </v-col>
                                        <v-col v-if="$vuetify.breakpoint.mobile && !data.isNew && !data.isEditing" class="text-right" cols="12">
                                            <p>Subtotal: {{ item.subTotal | toCurrency }}</p>
                                            <p>GST: {{ item.taxTotal | toCurrency }}</p>
                                            <v-divider class="my-1" />
                                            <div class="text-subtitle-1">Total: {{ item.amountTotal | toCurrency }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col v-if="(data.isNew || data.isEditing) && currentPolicy != null && (!$vuetify.breakpoint.mobile || currentCol == 1)" key="2" class="flex-grow-1">
                        <v-card flat outlined class="transparent">
                            <v-toolbar class="primary" dark dense>
                                <v-slide-x-transition>
                                    <v-btn v-if="currentCategory != null" large @click="currentCategory = null" icon>
                                        <v-icon large>mdi-arrow-left</v-icon>
                                    </v-btn>
                                    <v-btn v-else-if="$vuetify.breakpoint.mobile" large @click="currentCol = 0" icon>
                                        <v-icon large>mdi-arrow-left</v-icon>
                                    </v-btn>
                                </v-slide-x-transition>
                                <v-slide-x-transition hide-on-leave group>
                                    <v-toolbar-title v-if="currentCategory != null" key="1">{{ currentCategory.categoryName }}</v-toolbar-title>
                                    <v-toolbar-title v-else key="2">Products</v-toolbar-title>
                                </v-slide-x-transition>
                                <!-- <v-btn class="primary" small>
                                    All
                                </v-btn> -->
                                <v-spacer />
                                
                                <v-btn v-if="$vuetify.breakpoint.mobile" @click="currentCol = 2" text>Cart ({{ item.orderItems.length | toDisplayNumber }}) <v-icon right>mdi-arrow-right</v-icon></v-btn>
                            </v-toolbar>
                            <v-card class="ma-0 pa-0" flat :style="parentCardStyle">
                                <v-card class="overflow-y-auto" key="1" :style="standardCardStyle">
                                    <v-slide-x-transition hide-on-leave group>
                                        <div v-if="canUseCategories && currentCategory == null" class="d-flex align-center justify-center" key="0">
                                            <v-btn
                                                @click="useCategories = false"
                                                :class="useCategories ? '' : 'success--text'"
                                                text>All</v-btn>
                                            |
                                            <v-btn
                                                @click="useCategories = true"
                                                :class="useCategories ? 'success--text' : ''"
                                                text>By Category</v-btn>
                                        </div>
                                        <v-list v-if="useCategories && currentCategory == null" dense key="1" class="transparent">
                                            <v-card v-for="(category, ind) in categories" :key="ind" @click="currentCategory = category" class="ma-1 transparent">
                                                <v-list-item dense three-line>
                                                    <v-list-item-avatar rounded>
                                                        <v-img :src="productLogoURL(category.id)" class="my-auto">
                                                            <template v-slot:placeholder><v-icon size="40" class="primary--text">mdi-shape-outline</v-icon></template>
                                                        </v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ category.categoryName }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ category.categoryDescription }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-list>
                                        <v-list v-else-if="isLengthyArray(filteredOrderItems)" dense key="2" class="transparent">
                                            <template v-for="(orderItem, index) in filteredOrderItems">
                                                <Order-Line-Item 
                                                    canEdit
                                                    :item="orderItem"
                                                    showImages
                                                    :key="index"
                                                    @change="lineChanged" />
                                                <v-divider :key="'d' + orderItem.productID" />
                                            </template>
                                        </v-list>
                                        <div v-else key="3">
                                            Nothing Available To This Customer
                                        </div>
                                    </v-slide-x-transition>
                                </v-card>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.mobile || currentCol == 2" key="3" class="flex-shrink-1">
                        <v-card flat outlined class="transparent">
                            <v-toolbar class="primary" dark dense>
                                <v-btn v-if="seekDueDate" large @click="() => { seekDueDate = false }" icon>
                                    <v-icon large>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-btn v-else-if="$vuetify.breakpoint.mobile" large @click="() => { currentCol = (data.isNew || data.isEditing) ? 1 : 0 }" icon>
                                    <v-icon large>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ data.isNew ? 'Cart' : 'Ordered Items' }} ({{ item.orderItems.length | toDisplayNumber }})</v-toolbar-title>
                                <v-spacer />
                                <v-icon>mdi-cart</v-icon>
                            </v-toolbar>
                            <v-slide-x-transition>
                                <v-card class="ma-0 pa-0" flat :style="parentCardStyle">
                                    <v-card v-if="seekDueDate" class="overflow-y-auto" :style="standardCardStyle">
                                        <v-card-title>Please select a delivery date</v-card-title>
                                        <v-card-text>
                                            <BT-List-Endless
                                                v-model="item.dueDate"
                                                @change="() => save(false)"
                                                :canSearch="false"
                                                :dense="false"
                                                :isEditing="data.isNew || data.isEditing"
                                                :items="currentPolicy.orderSlots"
                                                itemText="deliverBy"
                                                itemValue="deliverBy"
                                                :onCanSelect="v => { return v == null || v.purchaseOrderID == null }"
                                                :onFilter="list => list.filter(x => x.purchaseOrderID == null)"
                                                sublabel="(Select a delivery date below)"
                                                textFilter="toDayShortDate"
                                                width="100%">
                                                <template #listItem="{ index, item, select }">
                                                    <v-list-item :value="item" :key="index" @click="select">
                                                        <v-list-item-avatar>
                                                            <v-icon>mdi-calendar</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.deliverBy | toDayShortDate }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </BT-List-Endless>
                                        </v-card-text>
                                    </v-card>
                                    <!-- v-else-if="(data.isNew || data.isEditing) && isLengthyArray(currentOrderItems.filter(x => x.quantity != 0))"  -->
                                    <v-card v-else class="overflow-y-auto" :style="standardCardStyle">
                                        <v-list v-if="(data.isNew || data.isEditing) && isLengthyArray(currentOrderItems.filter(x => x.quantity != 0))" dense>
                                            <v-slide-y-transition group>
                                                <template v-for="(orderItem, index) in currentOrderItems">
                                                    <Order-Line-Item 
                                                        canEdit
                                                        :item="orderItem"
                                                        showImages
                                                        :key="index"
                                                        @change="lineChanged" />
                                                    <v-divider :key="'d' + orderItem.productID" />
                                                </template>
                                            </v-slide-y-transition>
                                        </v-list>
                                        <v-list v-else-if="isLengthyArray(item.orderItems)" dense>
                                            <template v-for="(orderItem, index) in item.orderItems">
                                                <Order-Line-Item
                                                    :item="orderItem"
                                                    showImages
                                                    :key="index"
                                                    @change="lineChanged" />
                                                <v-divider :key="'d' + orderItem.productID" />
                                            </template>
                                        </v-list>
                                        <v-card v-else flat class="align-content-center justify-content-center text-center">
                                            <v-card-text>
                                                <div class="d-flex flex-column text-center">
                                                    <v-icon size="100">mdi-cart</v-icon>
                                                    No Order Yet
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                        <v-row v-if="isLengthyArray(item.orderItems)" no-gutters key="4" class="mx-4">
                                            <v-col cols="6" class="d-flex justify-center">
                                                <BT-Btn
                                                    v-if="data.isNew || data.isEditing"
                                                    @click="() => save(false)"
                                                    class="primary"
                                                    :disabled="!isChanged"
                                                    inline
                                                    :label="item.id == null ? 'Place Order' : 'Update Order'" />
                                                <BT-Btn
                                                    v-if="!data.isNew && !data.isEditing && item.isDispatched"
                                                    bladeName="stock-import"
                                                    icon="mdi-clipboard-outline"
                                                    :id="item.id"
                                                    label="Import Stock"
                                                    nav />
                                            </v-col>
                                            <v-col class="text-right" cols="6">
                                                <p>Subtotal: {{ item.subTotal | toCurrency }}</p>
                                                <p>GST: {{ item.taxTotal | toCurrency }}</p>
                                                <v-divider class="my-1" />
                                                <div class="text-subtitle-1">Total: {{ item.amountTotal | toCurrency }}</div>
                                            </v-col>
                                            <v-col v-if="msg != null" cols="12">
                                                <v-alert color="error" dark>{{ msg }}</v-alert>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card>
                            </v-slide-x-transition>
                        </v-card>
                    </v-col>
                </v-slide-x-reverse-transition>
            </v-container>
        </template>
        <template v-slot:page="{ item, data }">
            <v-dialog transition="dialog-bottom-transition" max-width="600" persistent v-model="showFinalDialog">
                <template #default>
                    <v-card>
                        <v-card-text class="text-center">
                            <v-slide-x-reverse-transition>
                                <v-card v-if="finalColumn == 0" flat outlined class="text-center">
                                    <v-icon size="80" class="success--text mt-4">mdi-check-decagram-outline</v-icon>
                                    <div class="text-h5">Order {{ data.isNew ? 'Placed!' : 'Updated!' }}</div>
                                    <v-btn 
                                        v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'"
                                        @click="finalColumn = 1">Make this a standing order</v-btn>
                                </v-card>
                                <v-card v-else-if="finalColumn == 1">
                                    <div class="text-body-1 pt-2">What delivery slot will this cover?</div>
                                    <BT-List
                                        v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'"
                                        customURL="/StandingOrderPolicy/StandingOrderPolicy"
                                        isSingle
                                        :itemID="item.supplyAgreementID"
                                        navigation="suppliers"
                                        :onPullSuccessAsync="res => res.slots.filter(x => x.isOn)">
                                        <template v-slot:listItem="e">
                                            <v-list-item @click="saveAsStandingOrder(item, e.item)">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ e.item.slotName }}</v-list-item-title>
                                                    <v-list-item-subtitle>(Next: {{ e.item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                                    <v-list-item-subtitle>{{ e.item.standingOrderIDs }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </BT-List>
                                </v-card>
                            </v-slide-x-reverse-transition>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="navBack" block class="primary">{{ finalColumn == 0 ? 'DONE' : 'Nevermind' }}</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-overlay :value="finalLoadingMsg != null" class="text-center">
                        <v-progress-circular indeterminate size="64" />
                        <p>{{ finalLoadingMsg }}</p>
                    </v-overlay>
                </template>
            </v-dialog>
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { calculateAutoItems, loadOrderItems, recalculateOrder, updateOrder } from '~helpers';

export default {
    name: 'Purchase-Order-Blade',
    components: {
        OrderLineItem: () => import('~home/purchase-orders/Ordering-Line-Item.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
    },
    data: function() {
        return {
            allOrderItems: [],
            autoOrderItems: [],
            canUseCategories: false,
            categories: [],
            currentCategory: null,
            currentCol: 0,
            currentOrder: null,
            currentPolicy: null,
            dueDateOptions: [],
            dateFrom: null,  
            dateRules: null,
            editToggle: false,
            finalColumn: 0,
            finalLoadingMsg: null,
            hideBladeHeader: false,
            loadingMsg: null,
            mAgreementID: null,
            msg: null,
            panelV: 0,
            seekDueDate: false,
            showFinalDialog: false,
            useCategories: true
        }
    },
    computed: {
        currentOrderItems() {
            return this.allOrderItems.filter(x => x.quantity != 0)
        },
        filteredOrderItems() {
            if (!this.isLengthyArray(this.categories) || !this.useCategories) {
                return this.allOrderItems;
            }
            else if (this.currentCategory != null) {
                if (this.currentCategory.id == 'other') {
                    return this.allOrderItems.filter(z => z.data.product == null || z.data.product.categoryID == null);
                }
                else {
                    return this.allOrderItems.filter(z => z.data.product != null && z.data.product.categoryID == this.currentCategory.id);
                }
            }

            return [];
        },
        possibleLocations() {
            return this.currentPolicy != null ? this.currentPolicy.possibleLocations : [];
        },
        standardCardStyle() {
            return `max-height: calc(100vh - 160px);`; // background: ${this.$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.90)' : 'rgba(255, 255, 255, 0.75)'}`;
        },
        parentCardStyle() {
            return `height: calc(100vh - 160px); background: ${this.$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.90)' : 'rgba(255, 255, 255, 0.65)'}`;
        },
        title() {
            if (this.currentOrder?.id == null) {
                return 'New Purchase Order';
            }
            else {
                return 'Purchase Order';
            }
        }
    },
    destroyed() {
        this.$BlitzIt.style.undoTemporaryColor(this.$vuetify);
        this.$BlitzIt.navigation.updateBackgroundID(null);
    },
    methods: {
        stopEditing() {
            this.editToggle = !this.editToggle;
            this.hideBladeHeader = false;
        },
        getRules(oItem) {
            const rules = []
            if (oItem.data.rules != null) {
                console.log(oItem)
                var incRule = oItem.data.rules.find(x => x.rule == 'QuantityInIncrements')
                if (incRule) {
                    rules.push(v => v % incRule.quantity == 0 || `Must order ${oItem.data.product?.productName} in increments of ${incRule.quantity}`)
                }

                var moqRule = oItem.data.rules.find(x => x.rule == 'QuantityMoreThan')
                if (moqRule) {
                    rules.push(v => v == 0 || v >= moqRule.quantity || `Minimum order quantity for ${oItem.data.product?.productName} is ${moqRule.quantity}`)
                }
            }

            return rules
        },
        onCanSave(order) {
            //confirm order items are according to rules
            if (order != null && this.isLengthyArray(this.allOrderItems)) {
                const ruleResults = this.allOrderItems.flatMap(x => {
                    const rules = this.getRules(x)
                    return rules.map(r => r(x.quantity))
                })
                
                const strings = ruleResults.filter(x => x !== true)
                if (this.isLengthyArray(strings))
                    return strings[0]
            }

            if (this.currentPolicy?.orderingArrangement == 'Slotted') {
                if (order.dueDate == null) {
                    //require due date
                    this.seekDueDate = true;
                    return 'Please select a delivery slot';
                }
            }

            return null;
        },
        getOrderToSave(bladeData, order) {
            if (order.id == null) {
                //is new so remove all auto order items
                order.orderItems = order.orderItems.filter(x => x.productID != null);
            }
            else {
                //is editing existing so keep all order items (?)
            }

            return order;
        },
        async loadPolicy(bladeData, order) {
            try {
                this.loadingMsg = 'Loading Policy';

                var resData = await this.$BlitzIt.api.getById('suppliers', order.supplyAgreementID, null, null, '/get/Policy');
                var policy = resData.data.data;
                
                if (policy == null) {
                    this.msg = 'No policy found';
                    return;
                }

                await this.setPolicy(policy, order)

                this.hideBladeHeader = true;

                // if (policy.orderingArrangement === 'Slotted') {
                //     for (var i = 0; i < policy.orderSlots.length; i++) {
                //         var slot = policy.orderSlots[i];
                //         if (!slot.purchaseOrderID) {
                //             this.dueDateOptions.unshift(slot.deliverBy);
                //         }
                //     }
                    
                //     this.dueDateOptions.sort();
                // }
                // else if (policy.requireLeadTime) {
                //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                //     if (!policy.isAvailableWeekends) {
                //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                //     }
                // }
                // else {
                //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                //     if (!policy.isAvailableWeekends) {
                //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                //     }
                // }

                // this.currentPolicy = policy;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null
            }
        },
        async selectPolicy(agrID, order) {
            if (agrID == null) {
                this.msg = 'No supplier selected';
                return;
            }

            try {
                this.loadingMsg = 'Loading Policy';

                var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/get/Policy');
                var policy = resData.data.data;
                
                if (policy == null) {
                    this.msg = 'No policy found';
                    return;
                }

                order.isBuyerCourier = policy.isRequirePickup ? true : policy.isPreferPickup,
                order.locationID = policy.defaultLocationID;
                order.location = policy.defaultLocation;
                order.orderItems = [];
                order.subTotal = 0;
                order.taxTotal = 0;
                order.amountTotal = 0;
                order.supplyAgreementID = agrID;

                await this.setPolicy(policy, order);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null
            }
        },
        async setPolicy(policy, order) {
            if (policy.orderingArrangement === 'Slotted') {
                for (var i = 0; i < policy.orderSlots.length; i++) {
                    var slot = policy.orderSlots[i];
                    if (!slot.purchaseOrderID) {
                        this.dueDateOptions.unshift(slot.deliverBy);
                    }
                }
                
                this.dueDateOptions.sort();
            }
            else if (policy.requireLeadTime) {
                this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                if (!policy.isAvailableWeekends) {
                    this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                }
            }
            else {
                this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                if (!policy.isAvailableWeekends) {
                    this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                }
            }

            this.currentPolicy = policy;

            //prep order items
            this.currentCategory = null;
            this.useCategories = true;

            this.allOrderItems = loadOrderItems(order, policy);

            this.autoOrderItems = calculateAutoItems(policy, this.allOrderItems, order);

            //update order?
            updateOrder(order, policy, this.allOrderItems, this.autoOrderItems);

            var categoryIDs = [...new Set(policy.orderItems.filter(z => z.product != null && z.product.categoryID != null).map(x => x.product.categoryID))];
            this.canUseCategories = this.isLengthyArray(categoryIDs);

            if (this.isLengthyArray(categoryIDs)) {
                this.categories = await this.$BlitzIt.store.getAll('public-product-categories', { ids: categoryIDs.toString() });

                if (this.isLengthyArray(this.categories)) {
                    if (policy.orderItems.some(z => z.product == null || z.product.categoryID == null)) {
                        this.categories.push({
                            id: 'other',
                            categoryName: 'Other'
                        });
                    }
                }
            }
            else {
                this.useCategories = false;
            }

            //set background
            this.$BlitzIt.navigation.updateBackgroundID(this.currentPolicy.sellerID);
        },
        async saveAsStandingOrder(order, slot) {
            try{
                this.finalLoadingMsg = 'Saving Standing Order';
                console.log(slot);

                var std = this.copyDeep(order);
                std.standingOrderName = slot.slotName;

                var res = await this.$BlitzIt.store.post(
                    'standing-purchase-orders', 
                    std);

                await this.$BlitzIt.api.post(
                    'standing-purchase-orders', {
                    orderSlotID: slot.id,
                    standingOrderID: res.id
                }, null, '/post/Remove');

                await this.$BlitzIt.api.post(
                    'standing-purchase-orders', {
                    orderSlotID: slot.id,
                    standingOrderID: res.id
                }, null, '/post/Add');

                this.showFinalDialog = false;
            }
            finally {
                this.finalLoadingMsg = null;
                this.navBack();
            }
        },
        async copyLastOrder(policy, order) {
            await this.populateWithLastOrder(policy, order);

            if (this.$vuetify.breakpoint.mobile) {
                this.currentCol = 2;
            }

            await this.setPolicy(policy, order);
        },
        navBack() {
            this.$router.push({ name: 'customer-dashboard' });
            // console.log('navving back');
            // this.$BlitzIt.navigation.navBack();
        },
        async populateWithLastOrder(policy, order) {
            //find last order
            if (order.supplyAgreementID == null || policy == null) {
                return;
            }

            var lastOrderRes = await this.$BlitzIt.store.getAll('purchase-orders', { agreementID: order.supplyAgreementID, takeAmount: 1, includeDetails: true }, true);
            if (this.isLengthyArray(lastOrderRes)) {
                console.log('latest order');
                console.log(lastOrderRes[0]);
                var lastOrder = lastOrderRes[0];
                lastOrder.orderItems.forEach(o => {
                    if (o.productID != null && policy.orderItems.some(z => z.productID == o.productID)) {
                        order.orderItems.push(this.copyDeep(o));
                    }
                });

                order.locationID ??= lastOrder.locationID;
                order.location ??= lastOrder.location;
                order.isBuyerCourier = lastOrder.isBuyerCourier;
            }
            else {
                var lastInvRes = await this.$BlitzIt.store.getAll('supplier-invoices', { agreementID: order.supplyAgreementID, sortBy: 'IssuedOn', takeAmount: 1, includeDetails: true }, true);
                console.log(lastInvRes);
                if (this.isLengthyArray(lastInvRes)) {
                    console.log('latest invoice');
                    console.log(lastInvRes[0]);
                    var lastInvoice = lastInvRes[0];
                    lastInvoice.lineItems.forEach(l => {
                        var policyItem = policy.orderItems.find(z => z.productID == l.itemID);
                        if (policyItem != null) {
                            order.orderItems.push({
                                id: null,
                                rowVersion: null,
                                lineItemCode: null,
                                description: l.description,
                                productID: l.itemID,
                                quantity: l.onOrder,
                                unitPrice: l.unitPrice,
                                discount: 0,
                                sortNumber: l.sortNumber,
                                tags: l.tags,
                                taxType: l.taxType,
                                isPlaceholder: false,
                                placeholderProductID: null
                            })
                        }
                    })
                }
            }
        },
        async newOrder(bladeData, order) {
            //get policy
            this.hideBladeHeader = true;

            try {
                var blankOrder = null;
                var agrID = null;

                agrID = bladeData.data?.agreementID;
                blankOrder = {
                    isBuyerCourier: false,
                    locationID: null,
                    location: null,
                    orderItems: [],
                    supplyAgreementID: null,
                    dueDate: null,
                };
                
                this.loadingMsg = '...preparing for new order';
                
                if (agrID == null) {
                    return blankOrder;
                }
                else {
                    var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/get/Policy');
                    var policy = resData.data.data;
                    if (policy == null) {
                        console.log('b')
                        return blankOrder;
                    }

                    order = {
                        isBuyerCourier: policy.isRequirePickup ? true : policy.isPreferPickup,
                        locationID: policy.defaultLocationID,
                        location: policy.defaultLocation,
                        orderItems: [],
                        supplyAgreementID: agrID,
                        dueDate: null,
                    };

                    var repeatLastOrder = bladeData.data?.repeatLast;
                    var standingOrderID = bladeData.data?.standingOrderID;

                    if (repeatLastOrder) {
                        await this.populateWithLastOrder(policy, order);

                        // //find last order
                        // var lastOrderRes = await this.$BlitzIt.store.getAll('purchase-orders', { agreementID: agrID, takeAmount: 1, includeDetails: true });
                        // if (this.isLengthyArray(lastOrderRes)) {
                        //     var lastOrder = lastOrderRes[0];
                        //     lastOrder.orderItems.forEach(o => {
                        //         if (o.productID != null && policy.orderItems.some(z => z.productID == o.productID)) {
                        //             order.orderItems.push(this.copyDeep(o));
                        //         }
                        //     });

                        //     order.locationID = lastOrder.locationID;
                        //     order.location = lastOrder.location;
                        //     order.isBuyerCourier = lastOrder.isBuyerCourier;
                        // }
                        // else {
                        //     var lastInvRes = await this.$BlitzIt.store.getAll('customer-invoices', { agreementID: agrID, sortBy: 'IssuedOn', takeAmount: 1, includeDetails: true });
                        //     if (this.isLengthyArray(lastInvRes)) {
                        //         var lastInvoice = lastInvRes[0];
                        //         lastInvoice.lineItems.forEach(l => {
                        //             var policyItem = policy.orderItems.find(z => z.productID == l.itemID);
                        //             if (policyItem != null) {
                        //                 order.orderItems.push({
                        //                     id: null,
                        //                     rowVersion: null,
                        //                     lineItemCode: null,
                        //                     description: l.description,
                        //                     productID: l.itemID,
                        //                     quantity: l.onOrder,
                        //                     unitPrice: l.unitPrice,
                        //                     discount: 0,
                        //                     sortNumber: l.sortNumber,
                        //                     tags: l.tags,
                        //                     taxType: l.taxType,
                        //                     isPlaceholder: false,
                        //                     placeholderProductID: null
                        //                 })
                        //             }
                        //         })
                        //     }
                        // }

                        this.panelV = 3;
                    }
                    else if (standingOrderID != null) {
                        //copy standing order
                        var standingOrder = await this.$BlitzIt.store.get('standing-purchase-orders', standingOrderID);
                        if (standingOrder != null) {
                            order.locationID = standingOrder.locationID;
                            order.location = standingOrder.location;
                            order.isBuyerCourier = standingOrder.isBuyerCourier;

                            standingOrder.orderItems.forEach(o => {
                                var policyItem = policy.orderItems.find(z => z.productID == o.productID);
                                if (policyItem != null) {
                                    order.orderItems.push({
                                        id: null,
                                        rowVersion: null,
                                        lineItemCode: null,
                                        description: o.product.productName,
                                        productID: o.productID,
                                        quantity: o.quantity,
                                        unitPrice: 0,
                                        discount: 0,
                                        sortNumber: policyItem.product.sortNumber,
                                        tags: policyItem.product.tags,
                                        taxType: policyItem.product.taxType,
                                        isPlaceholder: false,
                                        placeholderProductID: null
                                    })
                                }
                            });

                            this.panelV = 3;
                        }
                    }

                    order.dueDate = bladeData.data?.dueDate;

                    // if (policy.orderingArrangement === 'Slotted') {
                    //     for (var i = 0; i < policy.orderSlots.length; i++) {
                    //         var slot = policy.orderSlots[i];
                    //         if (!slot.purchaseOrderID) {
                    //             this.dueDateOptions.unshift(slot.deliverBy);
                    //         }
                    //     }
                      
                    //     this.dueDateOptions.sort();
                    // }
                    // else if (policy.requireLeadTime) {
                    //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    //     if (!policy.isAvailableWeekends) {
                    //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    //     }
                    // }
                    // else {
                    //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    //     if (!policy.isAvailableWeekends) {
                    //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    //     }
                    // }

                    order = recalculateOrder(order, policy);
                    
                    await this.setPolicy(policy, order);

                    return order;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async orderPulled(order) {
            if (order != null && this.isLengthyArray(order.orderItems)) {
                order.orderItems = order.orderItems.orderBy('sortNumber');
            }

            this.currentOrder = order;

            return order;
        },
        // async pullForPrint(item, id) {
        //     return {
        //         invoice: item,
        //         delivery: null,
        //         id: id
        //     }
        // },
        async pullSuppliers(list, order) {
            //move on if only 1 supplier
            if (this.isArrayOfLength(list, 1)) {
                await this.selectPolicy(list[0].id, order);
            }

            return list;
        },
        lineChanged() {
            this.autoOrderItems = calculateAutoItems(this.currentPolicy, this.allOrderItems, this.currentOrder)

            updateOrder(
                this.currentOrder,
                this.currentPolicy,
                this.allOrderItems,
                this.autoOrderItems
            );
        },
        async updateCurrentOrder(order, saveFunc = null) {
            this.currentOrder.orderItems = order.orderItems;
            this.currentOrder.taxTotal = order.taxTotal;
            this.currentOrder.subTotal = order.subTotal;
            this.currentOrder.amountTotal = order.amountTotal;

            if (saveFunc != null) {
                await saveFunc
            }
        }
    }
}
</script>